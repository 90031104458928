import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Lineup.css";

const Lineup = ({ date, artists }) => {
    const [, setwindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setwindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleArtistClick = (id) => {
        navigate(`/artist/${id}`);
    };

    const sortedArtists = artists.slice().sort((a,b)=>{
        const createConcertDate = (time) => {
            const [hour, minute] = time.split(":").map(Number);
            // if hour less than 6 = next day
            // year does not count
            return new Date(2020, 0, hour < 6 ? 2 : 1, hour, minute)
        };
        
        const dateA = createConcertDate(a.concertTime)
        const dateB = createConcertDate(b.concertTime)
        return dateA-dateB



    });


    const renderArtistCard = (artist) => {
        return (
            <div key={artist.id}
                className="lineup-item"
                onClick={() => handleArtistClick(artist.id)}
                role="button" 
            >
                <div
                    className="lineup-item-bg"
                    style={{ backgroundImage: `url(${artist.imageUrl})` }}
                ></div>
                <div className="lineup-item-content">
                    <div className="lineup-artist-name">{artist.name}</div>
                    <div className="lineup-artist-details">
                        {artist.concertLocation} {artist.concertTime}
                    </div>
                </div>
            </div>
        );
    };

    if (!Array.isArray(artists) || artists.length === 0) {
        console.error("artists is not an array or it is empty");
        return null;
    }

    return (
        <div className="lineup-component"><h1 className="date">{date}</h1><div className="lineup-container">
            {sortedArtists.map((artist) => renderArtistCard(artist))}
        </div></div>
    );
};

export default Lineup;
